/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const PTSerif_400Regular = require('./PTSerif_400Regular.ttf');
export const PTSerif_400Regular_Italic = require('./PTSerif_400Regular_Italic.ttf');
export const PTSerif_700Bold = require('./PTSerif_700Bold.ttf');
export const PTSerif_700Bold_Italic = require('./PTSerif_700Bold_Italic.ttf');
